//font
@font-face {
    font-family: 'title';
    src: url('../fonts/DIN2014-Bold.woff2') format('woff2'), url('../fonts/DIN2014-Bold.woff') format('woff'), url('../fonts/DIN2014-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'text';
    src: url('../fonts/DIN2014-Regular.woff2') format('woff2'), url('../fonts/DIN2014-Regular.woff') format('woff'), url('../fonts/DIN2014-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'font-link';
    src: url('../fonts/DIN2014-DemiBold.woff2') format('woff2'), url('../fonts/DIN2014-DemiBold.woff') format('woff'), url('../fonts/DIN2014-DemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// Body
// $body-bg: #f8fafc;
// Typography
$font-family-sans-serif: 'chipmong',
sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
// Colors
$text-body: #212529;
$main: #009444;
$text: #333;
$red-main: #fa695f;
$blue-main:#68b8e7;
$orange-main:#f57e20;
$green-main:#51be95;
$violet-main:#512e89;
$text-bold:#000000;
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
// date investment
$main-bg:#2A524E;
$white: #ffffff;
$ha-color:#f16522;
$footer-bg:#212529;
$admin-green: #2A524E;
