@font-face {
  font-family: "title";
  src: url("../../fonts/DIN2014-Bold.woff2") format("woff2"), url("../../fonts/DIN2014-Bold.woff") format("woff"), url("../../fonts/DIN2014-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "text";
  src: url("../../fonts/DIN2014-Regular.woff2") format("woff2"), url("../../fonts/DIN2014-Regular.woff") format("woff"), url("../../fonts/DIN2014-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "font-link";
  src: url("../../fonts/DIN2014-DemiBold.woff2") format("woff2"), url("../../fonts/DIN2014-DemiBold.woff") format("woff"), url("../../fonts/DIN2014-DemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

.new-body {
  position: fixed;
  overflow-y: scroll;
}

.title {
  font-family: "title";
}

.text p {
  font-family: "text";
}

.ingredients {
  font-family: "text";
}

.font-link {
  font-family: "font-link";
}

.page-area,
.navbar-are,
.footer-area {
  width: 100%;
  padding: 0;
}

h1.title {
  font-size: 45px;
}

h2.title {
  font-size: 42px;
}

h3.title {
  font-size: 40px;
}

h4.title {
  font-size: 35px;
}

p.sub-title {
  font-size: 20px;
}

a.link-btn {
  font-size: 20px;
}

.btn-main a {
  border: 2px solid;
  border-radius: 30px;
  padding: 7px 15px;
  font-size: 14px;
  display: inline-block;
  background-color: #009444;
  text-transform: uppercase;
  color: white;
}

.btn-main a .icon {
  transition: 0.3s;
  position: relative;
  padding-left: 0px;
}

.btn-main a:hover .icon {
  padding-left: 10px;
}

a.know-more {
  font-size: 20px;
}

p.text-corner {
  font-size: 30px;
}

p.text-below {
  font-size: 18px;
}

.morecontent span {
  display: none;
}

.morelink {
  display: block;
  color: #2A524E;
}

.morelink:hover {
  color: #BF943A;
}

.head-page {
  padding: 80px 0;
  background-color: #F9F9F9;
}

.head-page h2.title {
  font-size: 60px;
  font-weight: 600;
}

.head-page .sub-title {
  font-size: 20px;
}

strong.head-title {
  color: #009444;
}

.container {
  width: 80%;
  max-width: 1530px;
}

.msb-4 {
  margin-top: 4%;
  margin-bottom: 4%;
}

.my-10 {
  margin-top: 5%;
  margin-bottom: 5%;
}

.sy-4 {
  padding: 4rem 0;
}

.sy-2 {
  padding: 2rem 0;
}

.p-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-10 {
  padding: 10px 10px 10px 10px;
}

.sb-4 {
  padding-bottom: 4%;
}

.sy-5 {
  padding-top: 5%;
  padding-bottom: 5%;
}

.b-radius {
  border-radius: 20px;
  border: 1px solid #efefef;
}

.text-body {
  padding-top: 15px;
}

ul.share li i {
  font-size: 25px;
  color: #009444;
}

.line-text,
.line-blue-center,
.line-red-center,
.line-red {
  content: "";
  width: 60px;
  position: relative;
  height: 3px;
}

.bg-green {
  background-color: #51be95;
}

section.banner {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  position: relative;
}

section.banner::before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000000;
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
}

section.banner .container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}

section.banner .container .banner_title h1 {
  color: #fff;
}

section.banner .container .bannder_breadcrumb .breadcrumb {
  padding: 0;
  background-color: unset;
}

section.banner .container .bannder_breadcrumb .breadcrumb li.active a {
  color: #fff;
}

section.banner .container .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}

.one-line {
  -webkit-line-clamp: 1;
  /* number of lines to show */
}

.two-line {
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-height: 1.5;
  height: 45px;
}

.three-line {
  -webkit-line-clamp: 3;
}

.four-line {
  -webkit-line-clamp: 4;
}

.five-line {
  -webkit-line-clamp: 5;
}

.one-line,
.two-line,
.three-line,
.four-line,
.five-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  align-items: center;
}

.line-blue-center {
  background: #009444;
  margin: 10px auto;
}

.line-text {
  margin-top: 40px;
  background: #BF943A;
}

.line-red {
  background: #fa695f;
}

.line-red-center {
  background: #fa695f;
  margin: 10px auto;
}

a {
  color: #009444;
}

a:hover {
  text-decoration: none;
}

.bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

.center {
  display: flex;
}

.f-text {
  font-size: 30px !important;
}

.content-center {
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: 100%;
}

.content-box .content-text {
  padding: 15px 0;
}

.btn-box {
  margin-top: 20px;
}

.btn-box a {
  color: #2A524E;
}

.btn-box a:hover {
  color: #f16522;
}

.row.list-mentor {
  margin-right: -30px;
  margin-left: -30px;
}

.row.list-mentor .col-4 {
  padding: 30px;
}

.mentor .image {
  margin-bottom: 15px;
  border-radius: 20px;
}

.animation {
  transition: 0.3s;
  transform: translateY(0);
}

.animation:hover {
  transform: translateY(-3%);
}

.cs-shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.blog {
  border: 1px solid #efefef;
  border-radius: 10px;
}

.blog .image.thumbnail {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.blog .info {
  display: flex;
  padding: 0.7rem;
}

.blog .info .date {
  margin-left: 0.7rem;
}

.blog .blog-content {
  padding: 0 0.7rem 1rem 0.7rem;
}

.blog .blog-content .blog-title h3 {
  font-size: 1.2rem;
}

.bg-gray {
  background-color: #f0f4f8;
}

.swiper-container {
  padding: 0;
}

.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next,
.swiper-button-prev {
  height: 50px;
  width: 50px;
  background-color: white;
  display: inline-block;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 99 !important;
  top: 40%;
  -webkit-box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.6);
  box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.6);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  height: 50px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #009444;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.modal {
  padding-right: 0 !important;
}

.modal-header .close {
  position: absolute;
  right: 25px;
  top: 23px;
  height: 40px;
  width: 40px;
  opacity: 1;
  color: #fff;
  padding: 10px;
  background-color: #003664;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog.display {
  width: 60%;
  max-width: 1920px;
}

.modal-dialog.display .modal-content {
  top: 50px;
}

.modal-dialog.display .modal-content .content-text {
  height: 200px;
  overflow: auto;
}

.modal-dialog.display .modal-content .content-text::-webkit-scrollbar {
  width: 2px;
  background-color: #fff;
}

.modal-dialog.display .modal-content .content-text::-webkit-scrollbar-thumb {
  background-color: #000;
}

.modal-dialog {
  margin: auto !important;
}

.con-social ul.nav li a {
  font-size: 30px;
  padding: 0 10px 0 0;
}

ul.list-benefit li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

ul.list-benefit li .icon i {
  display: inline-block;
  font-size: 10px;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  background: #009444;
  border-radius: 63% 37% 30% 70%/50% 45% 55% 50%;
  color: #ffffff;
  margin-right: 10px;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
}

ul.list-benefit li .info p {
  margin: 0;
}

#buttons {
  color: #fff;
  text-decoration: none;
  position: fixed;
  z-index: 100;
}

#buttons {
  position: fixed;
  bottom: 0;
  right: 2%;
  top: 350px;
  z-index: 200;
  cursor: pointer;
  font-size: 1.6rem;
  height: 40px;
  width: 40px;
  margin: auto;
  background-color: #2A524E;
  border-radius: 50%;
}

#buttons span {
  margin-left: 15px;
}

#buttons span i {
  font-size: 20px;
  position: absolute;
  top: 8px;
  left: 30%;
  font-weight: 500;
}

.fly {
  position: absolute;
  animation: launch ease-in-out 3s infinite;
}

@keyframes launch {
  100% {
    transform: translateY(-1500px);
  }
}

#button:hover span:last-child {
  transform: translateY(-45px);
}

@media only screen and (max-width: 1170px) {
  .container {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  #slider {
    margin-top: 58px !important;
  }
}

@media only screen and (max-width: 992px) {
  h1.title,
  h2.title {
    font-size: 40px !important;
  }
}

@media only screen and (max-width: 768px) {
  h1.title,
  h2.title {
    font-size: 30px !important;
  }
}

@media only screen and (max-width: 576px) {
  h1.title,
  h2.title {
    font-size: 25px !important;
  }
}

@media only screen and (max-width: 500px) {
  h1.title,
  h2.title {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 450px) {
  h1.title,
  h2.title {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 992px) {
  h4.title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 768px) {
  h4.title {
    font-size: 25px;
  }
}

@media only screen and (max-width: 576px) {
  h4.title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 500px) {
  h4.title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 576px) {
  p.sub-title {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 500px) {
  p.sub-title {
    font-size: 16px !important;
    margin: 0 !important;
  }
}

@media only screen and (max-width: 992px) {
  p.text-corner {
    font-size: 25px !important;
  }
}

@media only screen and (max-width: 768px) {
  p.text-corner {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 576px) {
  p.text-corner {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 576px) {
  a.know-more {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 576px) {
  p.text-below {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 992px) {
  .line-text {
    margin-top: 20px;
  }
}

